import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React from "react";
import { useCallback } from "react";
import Page from "../components/Page";
import { getTranslations } from "../helpers/translations";
import { getAssetURL } from "../helpers/assets";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import ContactUs, { ContactUsProps } from "../components/ContactUs";
import { ROUTES } from "../helpers/routes";
import { isPublished } from "../helpers/directus";

const ContactUsPage = ({ data }: { data: any }) => {
  const intl = useIntl();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.contact_us_page.translations",
      intl.locale
    );
  }, [data]);

  const getContactUsData = useCallback((): ContactUsProps => {
    const t = getRootTranslatedData();

    return {
      title: t?.general_title,
      simpleCards: [
        {
          isPublished: isPublished(t?.product_inquiries_status),
          image: {
            src: getAssetURL(t?.product_inquiries_image) ?? "",
            alt: t?.product_inquiries_image_alt,
          },
          title: t?.product_inquiries_title,
          description: t?.product_inquiries_description,
          button: {
            label: t?.product_inquiries_button_label,
            link: t?.product_inquiries_button_link || ROUTES.PRODUCT_INQUIRIES,
          },
        },
        {
          isPublished: isPublished(t?.us_canada_inquiries_status),
          image: {
            src: getAssetURL(t?.us_canada_inquiries_image) ?? "",
            alt: t?.us_canada_inquiries_image_alt,
          },
          title: t?.us_canada_inquiries_title,
          description: t?.us_canada_inquiries_description,
          button: {
            label: t?.us_canada_inquiries_button_label,
            link: t?.us_canada_inquiries_button_link || ROUTES.FOOD_SERVICE,
          },
        },
        {
          isPublished: isPublished(t?.international_inquiries_status),
          image: {
            src: getAssetURL(t?.international_inquiries_image) ?? "",
            alt: t?.international_inquiries_image_alt,
          },
          title: t?.international_inquiries_title,
          description: t?.international_inquiries_description,
          button: {
            label: t?.international_inquiries_button_label,
            link:
              t?.international_inquiries_button_link ||
              ROUTES.INTERNATIONAL_SALES,
          },
        },
      ],
      card4: {
        isPublished: isPublished(t?.email_inquiries_status),
        image: {
          src: getAssetURL(t?.email_inquiries_image) ?? "",
          alt: t?.email_inquiries_image_alt,
        },
        description: t?.email_inquiries_description,
        button: {
          label: t?.email_inquiries_button_label,
          link: t?.email_inquiries_button_link,
        },
      },
      card5: {
        isPublished: isPublished(t?.location_status),
        image: {
          src: getAssetURL(t?.location_image) ?? "",
          alt: t?.location_image_alt,
        },
        title: t?.location_title,
        description: t?.location_description,
      },
    };
  }, [getRootTranslatedData]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={
        <SEO
          {...parseSEO(getRootTranslatedData(), intl?.locale)}
          withChatBot={intl.locale === "en-US" ? true : false}
        />
      }
    >
      <ContactUs {...getContactUsData()} />
    </Page>
  );
};

export default ContactUsPage;

export const query = graphql`
  query ($locale: String) {
    directus {
      contact_us_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          general_title
          product_inquiries_description
          us_canada_inquiries_description
          international_inquiries_description
          email_inquiries_button_link
          location_description
          email_inquiries_description
          languages_code {
            code
            name
          }
          status
          product_inquiries_status
          us_canada_inquiries_status
          international_inquiries_status
          email_inquiries_status
          location_status
          product_inquiries_title
          product_inquiries_button_label
          product_inquiries_button_link
          product_inquiries_image_alt
          us_canada_inquiries_title
          us_canada_inquiries_button_label
          us_canada_inquiries_button_link
          us_canada_inquiries_image_alt
          international_inquiries_title
          international_inquiries_button_label
          international_inquiries_button_link
          international_inquiries_image_alt
          email_inquiries_image_alt
          email_inquiries_button_label
          location_title
          location_image_alt
          location_image {
            filename_disk
            id
          }
          email_inquiries_image {
            filename_disk
            id
          }
          international_inquiries_image {
            filename_disk
            id
          }
          us_canada_inquiries_image {
            filename_disk
            id
          }
          product_inquiries_image {
            filename_disk
            id
          }
        }
      }
    }
  }
`;
